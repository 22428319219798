import { vnModule_knockoutJS } from "../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS";
import { XMLHttpRequestHandler } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { vn_app_zone_notification } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone_notification";
import { adUnits_template } from "../components/templates/adUnits_template";
import {header} from "./header";

declare var ko: any;
declare var page: any;

export class adUnits extends vnModule_knockoutJS{

    protected XHRHdl: XMLHttpRequestHandler;
    protected adUnits_template: adUnits_template;

    /**
     * @inheritDoc
     */
    protected getModuleName(){

        return "ad_units";
    }

    /**
     * @inheritDoc
     */
    public buildContent(): Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("Ad Units Edit");

        // create the users form
        this.adUnits_template = new adUnits_template("adUnits_template", this.viewModel, this);

        return [this.adUnits_template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.getEditFormData();
    }

    /**
     * Hook.
     *
     * Saves the edits to a user.
     */
    protected saveEditAdsSetup(): void{

        this.save();
    }

    /**
     * Hook.
     *
     * Cancels the edits to a user.
     */
    protected cancelEditAdsSetup(){

        this.loadingIn(this.adUnits_template.builtTemplate);

        this.releaseLock();
        let idBrand = this.getVnAppZone().app.params.idBrand;

        // Redirect to earnings page or user list.
        this.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_WARNING, "Ads setup action canceled!");

        page.redirect(`/brand/${idBrand}/ads_setups`);
    }

    protected openAdsSetupPage(){

        let idBrand = this.getVnAppZone().app.params.idBrand;
        let id = this.getVnAppZone().app.params.id;

        page.redirect(`/brand/${idBrand}/ads_setups/edit/${id}`);

    }

    /**
     * Hook.
     *
     * Navigates to zone settings edit page.
     */
    protected editZoneSettings(idZone){

        let idBrand = this.getVnAppZone().app.params.idBrand;
        let id      = this.getVnAppZone().app.params.id;

        page(`/brand/${idBrand}/ads_setups/${id}/zones/${idZone}/edit`);
    }

    //
    // Hook.
    // Adds child zone.
    protected addChildZone(zone){

        let adsSetup   = this.adUnits_template.getTemplateViewModel().adsSetup();
        let zoneGroups = adsSetup.zoneGroups();

        for(let x in zoneGroups){

            let zones = zoneGroups[x].zones();

            for(let y in zones){

                if(zone.displayName === zones[y].name){

                    let lastPosition     = 0;
                    let positionToInsert = 0;

                    // Get last position value.
                    for(let w in zones){

                        if(zone.displayName === zones[w].displayName){

                            positionToInsert = parseInt(w);

                            if(zones[w].position() && typeof zones[w].position() !== 'function' && parseInt(zones[w].position()) > lastPosition && !zones[w].isDeleted()){

                                lastPosition = parseInt(zones[w].position());
                            }
                        }
                    }

                    let zoneName = new Date().getTime().toString();

                    let newZone = {
                        id: null,
                        name: zoneName,
                        type: 'child',
                        displayName: zone.displayName,
                        groupName: zone.groupName,
                        idBrand: zone.idBrand,
                        idParent: zone.id,
                        repeatableOptions: null,
                        idZoneDisplayType: 1,
                        idZonePositionType: 1,
                        isOpen: ko.observable(false),
                        isOptionOpen: ko.observable(false),
                        adUnits: ko.observableArray([]),
                        activeAdUnitsCount: ko.observable(0),
                        adUnitsCount: ko.observable(0),
                        isRepeatable: false,
                        isChild: true,
                        isSecondary: false,
                        isChildOrSecondary: true,
                        idSecondary: null,
                        secondary: null,
                        secondaryEnabled: ko.observable(false),
                        position: ko.observable(lastPosition + 1),
                        isDeleted: ko.observable(false),
                    };

                    newZone.position.subscribe(function(newValue){

                        newZone.position(newValue.replace(/\D/g, ''));

                        newZone.id = null;
                    });

                    // Add new zone.
                    zones.splice(Number(positionToInsert) + 1, 0, newZone);

                    break;
                }
            }

            zoneGroups[x].zones(zones);
        }

        adsSetup.zoneGroups(zoneGroups);
        this.adUnits_template.getTemplateViewModel().adsSetup(adsSetup);
    }

    //
    // Shows modal.
    protected showPositionWarning(data){

        this.confirmationModal.setContext('positionWarning');
        this.confirmationModal.setTitle('Cannot Proceed');
        this.confirmationModal.setDataConfirm(data);
        this.confirmationModal.setMessageMain('Child zones require a valid unique positive integer for position value to delete.');
        this.confirmationModal.setMessageSecondary('Please add a valid unique value and try again.');
        this.confirmationModal.show();
    }

    //
    // Hook.
    // Removes child zone.
    protected removeChildZone(zone){

        let groupName = zone.groupName;
        let position  = zone.position();

        if(!position || typeof position === 'function'){

            this.showPositionWarning(zone);
            return;
        }

        let adsSetup   = this.adUnits_template.getTemplateViewModel().adsSetup();
        let zoneGroups = adsSetup.zoneGroups();

        for(let x in zoneGroups){

            if(groupName !== zoneGroups[x].name){

                continue;
            }

            let zones     = zoneGroups[x].zones();
            let nameCount = 0;

            for(let y1 in zones){

                if(zones[y1].isDeleted()){

                    continue;
                }

                let thisZonePosition = zones[y1].position();

                if(!thisZonePosition || typeof thisZonePosition === 'function'){

                    continue;
                }

                if(zone.displayName === zones[y1].displayName && parseInt(position) === parseInt(thisZonePosition)){

                    nameCount++;
                }
            }

            if(nameCount > 1){

                this.showPositionWarning(zone);
                return;
            }
            else if(nameCount !== 0){

                for(let y2 in zones){

                    if(zones[y2].isDeleted()){

                        continue;
                    }

                    let thisZonePosition = zones[y2].position();

                    if(!thisZonePosition || typeof thisZonePosition === 'function'){

                        continue;
                    }

                    if(zone.displayName === zones[y2].displayName && parseInt(position) === parseInt(thisZonePosition)){

                        let adUnits = zones[y2].adUnits();

                        for(let z in adUnits){

                            adUnits[z].isDeleted(true);
                        }

                        zones[y2].isDeleted(true);
                        zones[y2].adUnits(adUnits);
                        break;
                    }
                }

                zoneGroups[x].zones(zones);
            }
        }

        adsSetup.zoneGroups(zoneGroups);
        this.adUnits_template.getTemplateViewModel().adsSetup(adsSetup);
    }

    //
    // Mark ad units as deleted.
    public markAdUnitsAsDeletedOrNot(zone, value){
        
        let groupName  = zone.groupName;
        let zoneId     = zone.id;
        let adsSetup   = this.adUnits_template.getTemplateViewModel().adsSetup();
        let zoneGroups = adsSetup.zoneGroups();

        for(let x in zoneGroups){

            if(groupName !== zoneGroups[x].name){

                continue;
            }

            let zones = zoneGroups[x].zones();

            for(let y1 in zones){

                if(zoneId !== zones[y1].id){

                    continue;
                }

                let adUnits = zones[y1].adUnits();

                for(let z in adUnits){

                    adUnits[z].isDeleted(value);
                }   
                
                zones[y1].adUnits(adUnits);
            }     
            
            zoneGroups[x].zones(zones);
            break;
        }

        adsSetup.zoneGroups(zoneGroups);
        this.adUnits_template.getTemplateViewModel().adsSetup(adsSetup);
    }

    /**
     * Requests a lock release.
     */
    protected releaseLock(): void{

        let idBrand = this.getVnAppZone().app.params.idBrand;
        let id = this.getVnAppZone().app.params.id;

        let formReq_params: Array<Array<string>> = [];

        // Request a lock release.
        this.XHRHdl = new XMLHttpRequestHandler(`/api/brand/${idBrand}/ads_setup/${id}/adUnits/release_lock/`, formReq_params, this);
        this.XHRHdl.execute();
    }

    /**
     * Obtain the dynamic data required to fill the form.
     */
    protected getEditFormData(): void{

        this.loadingIn(this.adUnits_template.builtTemplate);

        let idBrand = this.getVnAppZone().app.params.idBrand;
        let id = this.getVnAppZone().app.params.id || null;

        this.XHRHdl = new XMLHttpRequestHandler(`/api/brand/${idBrand}/ads_setup/${id}/ad_units/references/`, [], this);
        this.XHRHdl.onReadyStateFunction = this.onEditFormDataRequestReturn;
        this.XHRHdl.execute();
    }

    /**
     * Returns handler for edit form request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onEditFormDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateEditFormData(responseParsed);

                obj.loadingOut(obj.adUnits_template.builtTemplate);

                obj.getEditData();
            });
        }
    }

    /**
     * Obtain the dynamic data required to fill the form.
     */
    protected getEditData(takeover: boolean = false): void{

        let id = this.getVnAppZone().app.params.id || null;
        let idBrand = this.getVnAppZone().app.params.idBrand;

        if(id) {

            this.loadingIn(this.adUnits_template.builtTemplate);

            this.XHRHdl = new XMLHttpRequestHandler(`/api/brand/${idBrand}/ads_setup/${id}/adUnits/get`, [['takeover', (takeover) ? '1' : '0']], this);
            this.XHRHdl.onReadyStateFunction = this.onEditDataRequestReturn;
            this.XHRHdl.execute();
        }
    }

    /**
     * Handles the form data response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onEditDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateEditData(responseParsed);
                obj.loadingOut(obj.adUnits_template.builtTemplate);
            });
        }
    }

    /**
     * Update the filters.
     *
     * @param responseParsed
     */
    protected updateEditFormData(responseParsed: any): void{

        this.adUnits_template.getTemplateViewModel().dimensions(responseParsed.dimensions.reverse());
        this.adUnits_template.setAdTypes(responseParsed.adTypes);
        this.adUnits_template.getTemplateViewModel().adTemplates(responseParsed.adTemplates);
        this.adUnits_template.getTemplateViewModel().zones(responseParsed.zones);
        this.adUnits_template.getTemplateViewModel().allBidderTypes(responseParsed.bidderTypes);
        this.adUnits_template.getTemplateViewModel().floatingModes(responseParsed.floatingModes);
        this.adUnits_template.getTemplateViewModel().floatingPositions(responseParsed.floatingPositions);
        this.adUnits_template.getTemplateViewModel().sizePostfixes(responseParsed.sizePostfixes);
        this.adUnits_template.getTemplateViewModel().brand(responseParsed.brand);
        this.adUnits_template.getTemplateViewModel().styleAdvertiserTags(responseParsed.styleAdvertiserTags);
        this.adUnits_template.getTemplateViewModel().minimumTimeBuffer(responseParsed.minimumTimeBuffer);
        this.adUnits_template.getTemplateViewModel().repeatableTypes(responseParsed.repeatableTypes);
        this.adUnits_template.getTemplateViewModel().repeatableCountTypes(responseParsed.repeatableCountTypes);
        this.adUnits_template.getTemplateViewModel().repeatablePlacementTypes(responseParsed.repeatablePlacementTypes);
        this.adUnits_template.getTemplateViewModel().repeatableHtmlTags(responseParsed.repeatableHtmlTags);
        this.adUnits_template.getTemplateViewModel().zoneDisplayTypes(responseParsed.zoneDisplayTypes);
        this.adUnits_template.getTemplateViewModel().zonePositionTypes(responseParsed.zonePositionTypes);
        this.adUnits_template.getTemplateViewModel().videoContexts(responseParsed.videoContexts);
        this.adUnits_template.getTemplateViewModel().videoRequestModes(responseParsed.videoRequestModes);
        this.adUnits_template.getTemplateViewModel().refreshModes(responseParsed.refreshModes);
        this.adUnits_template.setAuctionsManagers(responseParsed.auctionsManagers);
        this.adUnits_template.setUserActivityTypes(responseParsed.userActivityTypes);
        this.adUnits_template.getTemplateViewModel().connectedAdZonePositions(responseParsed.connectedAdZonePositions);
        this.adUnits_template.getTemplateViewModel().idZoneConnectedRight(responseParsed.idZoneConnectedRight); // TODO: Remove temporary value.
        this.adUnits_template.getTemplateViewModel().adBehaviours(responseParsed.adBehaviours);
    }

    /**
     * Updates the form elements.
     *
     */
    protected updateEditData(responseParsed: any){

        let adsSetup        = responseParsed.data;
        let adUnits         = responseParsed.data.adUnits;
        let zones           = this.adUnits_template.getTemplateViewModel().zones();
        adsSetup.zoneGroups = this.groupAdUnitsPerZone(adUnits, adsSetup.zones, adsSetup.id);
        let zoneInfos       = this.groupZones(zones, adsSetup.zoneGroups, adsSetup.zones);

        this.adUnits_template.setZoneInfos(zoneInfos);
        delete adsSetup.adUnits;
        this.adUnits_template.setAdsSetup(adsSetup);

        let device          = responseParsed.device; 
        this.adUnits_template.getTemplateViewModel().device(device);
    }


    /**
     * Saves the user data.
     */
    protected save(): void{

        this.loadingIn(this.adUnits_template.builtTemplate);

        let formReq_params: Array<Array<string>> = [];

        let subViewModel = this.adUnits_template.getViewModelForRequest();
        console.log(subViewModel);

        this.prepareAdsSetupForBackend(subViewModel['adsSetup']);

        let idBrand = this.getVnAppZone().app.params.idBrand;
        let id = this.getVnAppZone().app.params.id || null;

        formReq_params.push(["data", ko.toJSON(subViewModel)] );

        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler(`/api/brand/${idBrand}/ads_setup/${id}/adUnits/save/`, formReq_params, this);
        XHRHdl.mode                      = XMLHttpRequestHandler_requestType.POST;
        XHRHdl.onReadyStateFunction      = this.onSaveReturn;
        XHRHdl.execute();
    }

    /**
     * Saves the user data.
     */
    protected saveZones(): void{

        this.loadingIn(this.adUnits_template.builtTemplate);

        let formReq_params: Array<Array<string>> = [];

        let subViewModel = this.adUnits_template.getViewModelForZonesSaveRequest();

        let idBrand = this.getVnAppZone().app.params.idBrand;
        let id = this.getVnAppZone().app.params.id || null;

        formReq_params.push(["data", ko.toJSON(subViewModel)] );

        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler(`/api/brand/${idBrand}/ads_setup/${id}/save_zones/`, formReq_params, this);
        XHRHdl.mode                      = XMLHttpRequestHandler_requestType.POST;
        XHRHdl.onReadyStateFunction      = this.onSaveZonesReturn;
        XHRHdl.execute();
    }



    /**
     * Handles save response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onSaveReturn(req, obj): Function{

        let idBrand = obj.getVnAppZone().app.params.idBrand;

        return function(){

            obj.manageResponse(req, obj, function(){

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'ads setup created successfully!');

                obj.releaseLock();
                page.redirect(`/brand/${idBrand}/ads_setups`);
            });

            if(req.request.status != 200){

                obj.unprepareAdsSetupForBackend(obj.adUnits_template.getTemplateViewModel().adsSetup());
            }

            obj.loadingOut(obj.adUnits_template.builtTemplate);
        }
    }

    /**
     * Handles save response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onSaveZonesReturn(req, obj): Function{

        let idBrand = obj.getVnAppZone().app.params.idBrand;

        return function(){

            obj.manageResponse(req, obj, function(){});

            obj.loadingOut(obj.adUnits_template.builtTemplate);
        }
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    public filterDropdownOnChange(): void{

    }

    //
    // Prepares repeatable options.
    protected setupRepeatableOptions(repeatableOptions){

        let repeatableType   = null;
        let countType        = null;
        let showSkipEvery    = false;
        let showDegradation  = false;
        let showDegradationPlus  = false;
        let showStopAds  = false;
        let isTypeCount      = false;
        let isTypeEntity     = false;
        let isTypeStickyRail = false;

        if(repeatableOptions.type.indexOf('Count') !== -1){

            repeatableType = 'count';
            countType      = repeatableOptions.type.split('Count')[0];
        }
        else if('basic' === repeatableOptions.type){

            repeatableType = 'entity';
        }
        else{

            repeatableType = repeatableOptions.type;
        }

        if(repeatableOptions.skipEvery !== null){

            showSkipEvery = true;
        }

        if(repeatableOptions.degradationCount !== null){

            showDegradation = true;
        }        
        
        if(repeatableOptions.degradationCountPlus !== null){

            showDegradationPlus = true;
        }

        if(repeatableOptions.stopAds !== null){

            showStopAds = true;

        }

        if('count' == repeatableType){

            isTypeCount = true;
        }
        else if('entity' == repeatableType){

            isTypeEntity = true;
        }
        else if('stickyRail' == repeatableType){

            isTypeStickyRail = true;
        }

        repeatableOptions.type                     = ko.observable(repeatableType);
        repeatableOptions.countType                = ko.observable(countType);
        repeatableOptions.startingPoint            = ko.observable(repeatableOptions.startingPoint);
        repeatableOptions.htmlTag                  = ko.observable(repeatableOptions.htmlTag);
        repeatableOptions.count                    = ko.observable(repeatableOptions.count);
        repeatableOptions.placement                = ko.observable(repeatableOptions.placement);
        repeatableOptions.skipEvery                = ko.observable(repeatableOptions.skipEvery || 0);
        repeatableOptions.degradationHtmlTag       = ko.observable(repeatableOptions.degradationHtmlTag);
        repeatableOptions.degradationPlacement     = ko.observable(repeatableOptions.degradationPlacement);
        repeatableOptions.degradationStartingPoint = ko.observable(repeatableOptions.degradationStartingPoint || 0);
        repeatableOptions.degradationStartingPointPlus = ko.observable(repeatableOptions.degradationStartingPointPlus || 0);
        repeatableOptions.stopAds                  = ko.observable(repeatableOptions.stopAds || 0);
        repeatableOptions.degradationEntityCount   = ko.observable(repeatableOptions.degradationEntityCount || 0);
        repeatableOptions.degradationEntityCountPlus   = ko.observable(repeatableOptions.degradationEntityCountPlus || 0);
        repeatableOptions.degradationCount         = ko.observable(repeatableOptions.degradationCount || 0);
        repeatableOptions.degradationCountPlus     = ko.observable(repeatableOptions.degradationCountPlus || 0);
        repeatableOptions.isContinuous             = ko.observable(repeatableOptions.isContinuous || false);
        repeatableOptions.railItemMargin           = ko.observable(repeatableOptions.railItemMargin || 0);
        repeatableOptions.idHelper                 = repeatableOptions.id;
        repeatableOptions.showSkipEvery            = ko.observable(showSkipEvery);
        repeatableOptions.showDegradation          = ko.observable(showDegradation);
        repeatableOptions.showDegradationPlus      = ko.observable(showDegradationPlus);
        repeatableOptions.showStopAds              = ko.observable(showStopAds);
        repeatableOptions.isTypeCount              = ko.observable(isTypeCount);
        repeatableOptions.isTypeEntity             = ko.observable(isTypeEntity);
        repeatableOptions.isTypeStickyRail         = ko.observable(isTypeStickyRail);

        repeatableOptions.type.subscribe(function(newValue){

            repeatableOptions.isTypeCount((newValue === 'count'));
            repeatableOptions.isTypeEntity((newValue === 'entity'));
            repeatableOptions.isTypeStickyRail((newValue === 'stickyRail'));
        }, this);

        return repeatableOptions;
    }

    private groupAdUnitsPerZone(adUnits, adsSetupZones, idAdsSetup){

        let adUnitsPerZone      = [];
        let adUnitsPerZoneGroup = [];
        let zonesGroups         = [];

        for(let adsSetupZone of adsSetupZones){

            let repeatableOptions = null;

            if(adsSetupZone.isRepeatable && adsSetupZone.repeatableOptions[idAdsSetup]){

                repeatableOptions = this.setupRepeatableOptions(adsSetupZone.repeatableOptions[idAdsSetup]);
            }
            else if(adsSetupZone.isRepeatable){

                repeatableOptions = this.setupRepeatableOptions({
                    id: new Date().getTime(),
                    type: 'characterCount',
                    startingPoint: 0,
                    htmlTag: '<table>',
                    count: 0,
                    placement: 'after',
                    skipEvery: 0,
                    degradationHtmlTag: '<table>',
                    degradationPlacement: 'after',
                    degradationStartingPoint: 0,
                    stopAds: 0,
                    degradationEntityCount: 0,
                    degradationCount: 0,
                    isContinuous: false,
                    railItemMargin: 0,
                });
            }

            let newZone = {
                id: adsSetupZone.id,
                name: adsSetupZone.name,
                type: adsSetupZone.type,
                displayName: (adsSetupZone.parent) ? adsSetupZone.parent.name : adsSetupZone.name,
                groupName: adsSetupZone.groupName,
                idBrand: adsSetupZone.idBrand,
                idParent: (adsSetupZone.parent) ? adsSetupZone.parent.id : null,
                repeatableOptions: repeatableOptions,
                idZoneDisplayType: adsSetupZone.zoneDisplayType[idAdsSetup],
                idZonePositionType: adsSetupZone.zonePositionType[idAdsSetup],
                isOpen: ko.observable(false),
                isOptionOpen: ko.observable(false),
                adUnits: ko.observableArray([]),
                activeAdUnitsCount: ko.observable(0),
                adUnitsCount: ko.observable(0),
                isRepeatable: adsSetupZone.isRepeatable,
                isChild: adsSetupZone.isChild,
                isSecondary: adsSetupZone.isSecondary,
                isChildOrSecondary: adsSetupZone.isChildOrSecondary,
                idSecondary: adsSetupZone.idSecondary,
                secondary: adsSetupZone.secondary,
                secondaryEnabled: ko.observable(adsSetupZone.secondaryEnabled || false),
                isFrontEndInjectable: adsSetupZone.isFrontEndInjectable,
                canRemoveVendorSpecificContainers: adsSetupZone.canRemoveVendorSpecificContainers,
                position: ko.observable(adsSetupZone.position),
                isDeleted: ko.observable(false),
            }

            newZone.position.subscribe(function(newValue){

                newZone.position(newValue.replace(/\D/g, ''));

                newZone.id = null;
            });

            let self = this;

            newZone.secondaryEnabled.subscribe(function(newValue){

                for(let zone1 of adUnitsPerZone){
            
                    if(!zone1.isRepeatable || !zone1.secondary){
        
                        continue;
                    }
        
                    for(let zone2 of adUnitsPerZone){
                        
                        if(zone2.id !== zone1.secondary.id){
        
                            continue;
                        }
        
                        zone2.isDeleted(!newValue);
                        self.markAdUnitsAsDeletedOrNot(zone2, !newValue);

                        break;
                    }
                }        
            });

            adUnitsPerZone.push(newZone);
        }

        for(let adUnit of adUnits){

            let currentAdUnitsPerZone = adUnitsPerZone.find(i => i.name === adUnit.zone.name);

            if(!currentAdUnitsPerZone){

                throw new Error('Unable to determine zone.');
            }

            let newAdUnitsCount = currentAdUnitsPerZone.adUnitsCount() + 1;

            currentAdUnitsPerZone.adUnits.push(adUnit);
            currentAdUnitsPerZone.adUnitsCount(newAdUnitsCount);

            if(adUnit.isActive) {

                let newActiveAdUnitsCount = currentAdUnitsPerZone.activeAdUnitsCount() + 1;
                currentAdUnitsPerZone.activeAdUnitsCount(newActiveAdUnitsCount);
            }
        }

        adUnitsPerZone.sort(function(a, b){

            return a.name.localeCompare(b.name, undefined, {numeric: true});
        });

        for(let zone1 of adUnitsPerZone){
            
            if(!zone1.isRepeatable || !zone1.secondary){

                continue;
            }

            for(let zone2 of adUnitsPerZone){
                
                if(zone2.id !== zone1.secondary.id){

                    continue;
                }

                if(zone2.adUnits().length > 0){

                    zone2.isDeleted(false);
                    zone1.secondaryEnabled(true);
                    break;
                }

                zone2.isDeleted(true);
                zone1.secondaryEnabled(false);
                break;
            }
        }

        for(let currentAdUnitsPerZone of adUnitsPerZone){

            if(typeof zonesGroups[currentAdUnitsPerZone['groupName']] === 'undefined' || zonesGroups[currentAdUnitsPerZone['groupName']] === null){

                zonesGroups[currentAdUnitsPerZone['groupName']] = []
                zonesGroups[currentAdUnitsPerZone['groupName']]['zones'] = []
            }

            zonesGroups[currentAdUnitsPerZone['groupName']]['zones'].push(currentAdUnitsPerZone);
        }

        for(let zoneGroupName in zonesGroups){

            adUnitsPerZoneGroup.push({'name': zoneGroupName, 'zones' : ko.observableArray(zonesGroups[zoneGroupName]['zones'])});
        }

        adUnitsPerZoneGroup.sort(function(a, b){

            return a.name.localeCompare(b.name, undefined, {numeric: true});
        });

        return adUnitsPerZoneGroup;
    }

    private prepareAdsSetupForBackend(adsSetup){

        console.log('adsetup before save');
        console.log(adsSetup);

        let adUnits = [];

        for (let zoneGroup of adsSetup.zoneGroups()) {

            for (let zone of zoneGroup.zones()) {

                for (let adUnit of zone.adUnits()) {

                    delete adUnit.byTimeHelpInfoButton;
                    delete adUnit.byTimePixelTriggerHelpInfoButton;
                    delete adUnit.byDistanceHelpInfoButton;
                    delete adUnit.whenAdComesInviewHelpInfoButton;
                    delete adUnit.prefetchHelpInfoButton;
                    delete adUnit.minimumTimeBufferHelpInfoButton;
                    delete adUnit.floatingTypeHelpInfoButton;
                    delete adUnit.floatingOffsetHelpInfoButton;
                    delete adUnit.floatingWidthAndHeightHelpInfoButton;
                    delete adUnit.floatingPositionHelpInfoButton;
                    delete adUnit.styleHelpInfoButton;
                    delete adUnit.dynamicallyInjectedHelpInfoButton;
                    delete adUnit.connectedBelowAdHelpInfoButton;
                    delete adUnit.stickySlideAnimationHelpInfoButton;
                    delete adUnit.interscrollerAnimationHelpInfoButton;
                    delete adUnit.doNotUseSpaceHelpInfoButton;
                    delete adUnit.useAutoScrollHelpInfoButton;
                    delete adUnit.videoRequestModes;
                    delete adUnit.videoContexts;
                    delete adUnit.userActivityTypes;

                    adUnits.push(adUnit);
                }
            }
        }

        console.log('ads before save');
        console.log(adUnits);
    }

    //
    // Fix ad units for further editing.
    private unprepareAdsSetupForBackend(adsSetup){

        for(let zoneGroup of adsSetup.zoneGroups()){

            for(let zone of zoneGroup.zones()){

                for(let adUnit of zone.adUnits()){

                    this.adUnits_template.addInfoButtons(adUnit, adUnit.order);
                }
            }
        }
    }

    //Build zoneGroups for manage zones section
    private groupZones(zones, adUnitsPerZoneGroup, adsSetupZones) {

        let zonesGroups = [];
        let zonesInfos = [];
        let selectedZones = [];

        for (let zone of zones) {

            if (typeof zonesGroups[zone.groupName] === 'undefined' || zonesGroups[zone.groupName] === null) {

                zonesGroups[zone.groupName] = [];
                zonesGroups[zone.groupName]['zones'] = [];
            }

            let adUnitsPerActualGroupZone = adUnitsPerZoneGroup.find(i => i.name === zone.groupName);

            if (typeof adUnitsPerActualGroupZone !== 'undefined' && adUnitsPerActualGroupZone !== null) {

                let adUnitZone = adUnitsPerActualGroupZone.zones().find(i => i.name === zone.name);

                if(typeof adUnitZone !== 'undefined' && adUnitZone !== null && adUnitZone.adUnitsCount() != 0){

                    zone.containsAdUnits = ko.observable(true);

                }else{

                    zone.containsAdUnits = ko.observable(false);

                }
            } else {

                zone.containsAdUnits = ko.observable(false);

            }

            zonesGroups[zone.groupName]['zones'].push(zone);
            zonesGroups[zone.groupName]['name'] = zone.groupName;
        }

        for (let adsSetupZone of adsSetupZones) {

            selectedZones.push(adsSetupZone.id);
        }


        for (let zoneGroupName in zonesGroups) {

            if (typeof zonesInfos['allZonesGroups'] === 'undefined' || zonesInfos['allZonesGroups'] === null) {

                zonesInfos['allZonesGroups'] = ko.observableArray([]);

            }

            zonesInfos['allZonesGroups'].push({'name': zoneGroupName, 'zones' : ko.observableArray(zonesGroups[zoneGroupName]['zones'])});
        }

        zonesInfos['selectedZones'] = ko.observableArray(selectedZones);
        zonesInfos['isVisible'] = ko.observable(false);

        return zonesInfos;

    }

    /** @inheritDoc */
    public confirmationModalOnClickConfirm(context, data): void{

        switch(context){

            case 'takeover':

                this.getEditData(true);
                break;

            case 'removeChildZone':

                this.removeChildZone(data);
                break;
        }
    }

    /** @inheritDoc */
    public confirmationModalOnClickClose(context, data): void{

        page.redirect(data);
    }
}
